import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateCategoryRequest } from "./category.request";
import { CreateCategoryDTO, createCategoryIntialStateDTO } from "./types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: createCategoryIntialStateDTO = {
  success_createCategory: false,
  error_createCategory: false,
  loading_createCategory: false,
  add_category_id: [],
};

export const createCategory = createAsyncThunk(
  "user/category",
  (data: CreateCategoryDTO, { dispatch }) => {
    return CreateCategoryRequest(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserDetails: (state, action: PayloadAction<CreateCategoryDTO>) => {
      return { ...state, userDetails: action.payload };
    },
    resetSuccessCreateCategory: (state) => {
      state.success_createCategory = false;
      state.error_createCategory = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCategory.pending, (state) => {
      state.loading_createCategory = true;
      state.success_createCategory = false;
      state.error_createCategory = false;
    });
    builder.addCase(createCategory.fulfilled, (state, action: AnyAction) => {
      state.loading_createCategory = false;
      state.success_createCategory =
        action?.payload?.message || "Category Created";
      state.error_createCategory = false;
      state.add_category_id = action.payload.data.id;
    });
    builder.addCase(createCategory.rejected, (state, action: AnyAction) => {
      state.loading_createCategory = false;
      state.success_createCategory = false;
      state.error_createCategory =
        action?.payload?.message || "Something went wrong";
    });
  },
});

export default categorySlice.reducer;
export const { reset, setUserDetails, resetSuccessCreateCategory } =
  categorySlice.actions;

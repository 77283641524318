import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { createBatchDTO } from "./types";

export const CreateBatch = (body: createBatchDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/batch`,
    data: body,
  });
};

export const GetBatchList = (data: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/batch/byInstitute/${data?.id}?batchConductType=${data?.batchConductType}`,
  });
};

export const GetBatchById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/batch/id/${id}`,
  });
};

export const UpdateBatch = (body: createBatchDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/batch`,
    data: body,
  });
};

export const DeleteDemoStudent = (body: any) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/nurture/lead/demo`,
    data: body,
  });
};

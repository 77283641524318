import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FileUploadRequest } from "./fileUpload.request";
import { FileUploadDTO, FileUploadInitialStateDTO } from "./types";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: FileUploadInitialStateDTO = {
  fileUploadSuccess: false,
  fileUploadError: false,
  fileUploadLoading: false,
  imageUrl: null,
};

export const fileUpload = createAsyncThunk(
  "user/fileUpload",
  async (data: FileUploadDTO, thunkAPI: any) => {
    return FileUploadRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const fileUploadSlice = createSlice({
  name: "file Upload",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fileUpload.pending, (state) => {
      state.fileUploadLoading = true;
      state.fileUploadSuccess = false;
      state.fileUploadError = false;
    });
    builder.addCase(fileUpload.fulfilled, (state, action: AnyAction) => {
      state.fileUploadLoading = false;
      state.fileUploadSuccess = action?.success?.message || "File Uploaded";
      state.fileUploadError = false;
      state.imageUrl = action?.payload;
    });
    builder.addCase(fileUpload.rejected, (state, action: AnyAction) => {
      state.fileUploadLoading = false;
      state.fileUploadSuccess = false;
      state.fileUploadError = action?.error?.message || "Something went wrong";
    });
  },
});

export default fileUploadSlice.reducer;
export const { reset } = fileUploadSlice.actions;

import axiosLib, { AxiosInstance } from "axios";

const AuthApiEndPoint = process.env.REACT_APP_AUTH_API_ENDPOINT || "";
const InstituteApiEndPoint = process.env.REACT_APP_INSTITUTE_API_ENDPOINT || "";
const OzontelApiEndPoint =
  "https://in1-ccaas-api.ozonetel.com/CAServices/AgentManualDial.php?";

const createInstance = (
  baseURL: string,
  requiresAuth: boolean = false
): AxiosInstance => {
  if (!baseURL) {
    throw new Error("Base URL is not defined");
  }

  const instance: AxiosInstance = axiosLib.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      "app-name": "nurture-web",
    },
  });
  if (requiresAuth) {
    instance.interceptors.request.use((config) => {
      const userString: string | null = localStorage.getItem("_auth_state");
      if (userString) {
        const user = JSON.parse(userString);
        const token: string | undefined = user?.token;
        if (token) {
          config.headers = {
            ...(config.headers as any),
            Authorization: `Bearer ${token}`,
          };
        }
      }
      return config;
    });
  }

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        console.error({
          url: error.response.config.url,
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
        if (error.response.status === 401) {
          localStorage.removeItem("_auth_state");
          window.location.href = "/";
        }
      } else {
        console.error(error.message);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

// Ozonetel
const createOzontelInstance = (baseURL: string): AxiosInstance => {
  if (!baseURL) {
    throw new Error("Base URL is not defined for Ozontel");
  }

  const instance: AxiosInstance = axiosLib.create({
    baseURL,
    headers: {
      "Content-Type": "text/plain",
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        console.error({
          url: error.response.config.url,
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      } else {
        console.error(error.message);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const axios = createInstance(AuthApiEndPoint);
export const axios_logout = createInstance(AuthApiEndPoint, true);
export const axios_signup = createInstance(InstituteApiEndPoint, true);
export const axios_institute_private = createInstance(
  `${InstituteApiEndPoint}/private`,
  true
);
export const axios_institute_public = createInstance(InstituteApiEndPoint);
export const axios_ozonetel = createOzontelInstance(OzontelApiEndPoint);

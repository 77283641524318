import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateContact, GetPublicWebformById } from "./publicwebform.request";
import { PublicWebformStateDTO } from "./types";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: PublicWebformStateDTO = {
  success: false,
  error: false,
  loading: false,
  contactUpdateSuccess: false,
  contactUpdateError: false,
  contactUpdateLoading: false,
  publicWebform: {},
};

export const createContact = createAsyncThunk(
  "campaign/createcontact",
  async (data: any, { dispatch }) => {
    return CreateContact(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: "Your response has already been recorded",
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const getPublicWebformById = createAsyncThunk(
  "publicwebform/getPublicWebformById",
  async (data: any, thunkAPI: any) => {
    return GetPublicWebformById(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const campaignSlice = createSlice({
  name: "publicWebform",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createContact.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      createContact.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      }
    );
    builder.addCase(createContact.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getPublicWebformById.pending, (state) => {
      state.contactUpdateLoading = true;
      state.contactUpdateSuccess = false;
      state.contactUpdateError = false;
    });
    builder.addCase(
      getPublicWebformById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.contactUpdateLoading = false;
        state.contactUpdateSuccess = true;
        state.contactUpdateError = false;
        state.publicWebform = action.payload;
      }
    );
    builder.addCase(
      getPublicWebformById.rejected,
      (state, action: AnyAction) => {
        state.contactUpdateLoading = false;
        state.contactUpdateSuccess = false;
        state.contactUpdateError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default campaignSlice.reducer;
export const { reset } = campaignSlice.actions;

import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { StudentDetailsDTO, StudentInitialStateDTO } from "./types";
import { GetStudentByInstituteIdRequest } from "./student.request";

export const initialState: StudentInitialStateDTO = {
  error: false,
  success: false,
  loading: false,
  studentDetails: null,
  studentListLoading: false,
};

export const getStudentByInstituteId = createAsyncThunk(
  "/student/instituteId",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
      branchId: string;
      assigneeId: string;
    },
    thunkAPI: any
  ) => {
    const {
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey,
      branchId,
      assigneeId,
    } = data;

    return GetStudentByInstituteIdRequest(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey,
      branchId,
      assigneeId
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentByInstituteId.pending, (state) => {
      state.studentListLoading = true;
      state.error = false;
    });
    builder.addCase(
      getStudentByInstituteId.fulfilled,
      (state, action: PayloadAction<StudentDetailsDTO>) => {
        state.studentListLoading = false;
        state.error = false;
        state.studentDetails = action?.payload;
      }
    );
    builder.addCase(
      getStudentByInstituteId.rejected,
      (state, action: AnyAction) => {
        state.studentListLoading = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default studentSlice.reducer;

import { axios_institute_private } from "../../../../../api/setup.intersepter";

// export const GetStudentByInstituteIdRequest = (Id: string) => {
//   return axios_institute_private({
//     method: "GET",
//     // url: `/student/mappedStudentByInstitute/${Id}?paymentStatus=All&courseId=All&batchId=All&branchId=All&batchStatus=All`,
//     url: `/student/mappedStudentByInstitute/${Id}?paymentStatus=All&courseId=All&batchId=All&branchId=All&batchStatus=All&limit=10`,
//   });
// };

export const GetStudentByInstituteIdRequest = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string,
  branchId: string,
  assigneeId: string
) => {
  let url = `/student/mappedStudentByInstitute/${id}?paymentStatus=All&courseId=All&batchId=All&branchId=${branchId}&batchStatus=All&limit=${limit}`;

  if (assigneeId) {
    url += `&assigneeId=${assigneeId}`;
  }
  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

import { axios_institute_private } from "../../../../api/setup.intersepter";

import {
  ConvertLeadToStudentDTO,
  CreateDemoDTO,
  CreateNoteDTO,
  LeadSwimlaneStatusUpdateDTO,
  NurtureLead,
  RecordPaymentDTO,
  UpdateAssigneeDTO,
  UpdatePriorityDTO,
  GetDemoByDateAndBAtchDTO,
  ComposeEmailDTO,
  LeadStatusUpdateDTO,
  DeleteNoteDTP,
  EditNoteDTO,
  DeleteTaskDTO,
  EditTaskDTO,
  DeleteDemoBookedDTO,
  leadEmiDTO,
  sendEmailDTO,
  DownloadReceiptDTO,
  CreateSourceDTO,
  CreateCommentDTO,
  DeleteCommentDTO,
  StudentAutoDebitDTO,
} from "./types";

export const NurtureLeadRequest = (body: NurtureLead) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead`,
    data: body,
  });
};

export const GetNurtureLeadBoard = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/nurture/loadBoard/${id}`,
  });
};

export const UpdateLeadSwimlaneStatus = (body: LeadSwimlaneStatusUpdateDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead/updateEnquirySwimlaneStatus `,
    data: body,
  });
};

export const GetLeadActivityList = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/activity/byEnquiry/${id}`,
  });
};

export const GetLeadTaskList = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/task?enquiryId=${id}&type=ALL`,
  });
};

export const GetLeadNoteList = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/note?enquiryId=${id}&swimlaneState=ALL`,
  });
};

export const GetLeadPaymentList = (id: string) => {
  return axios_institute_private({
    method: "GET",
    // url: `/nurture/lead/payment?enquiryId=${id}`,
    // url: `/nurture/lead/payment?enquiryId=${id}&paymentStatus=PAID`,
    url: `/nurture/lead/payment?enquiryId=${id}&paymentStatus=ALL`,
  });
};

export const UpdateLead = (body: NurtureLead) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead`,
    data: body,
  });
};

export const GetLeadById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/nurture/lead/id/${id}`,
  });
};

export const CreateNote = (body: CreateNoteDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/note`,
    data: body,
  });
};

export const CreateTask = (body: CreateNoteDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/task`,
    data: body,
  });
};

export const RecordPayment = (body: RecordPaymentDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/payment`,
    data: body,
  });
};

export const CreateDemoRequest = (body: CreateDemoDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/demo`,
    data: body,
  });
};

export const GetBatchByInstitute = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/batch/byInstitute/${id}?batchConductType==ALL`,
  });
};

export const GetDemoByBatchId = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `nurture/lead/demo?batchId=${id}`,
  });
};

export const GetDemoByDateAndBAtchRequest = (
  body: GetDemoByDateAndBAtchDTO
) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/demo?batchId=${body?.batchId}&demoStartDate=${body?.demoStartDate}&demoEndDate=${body?.demoEndDate}`,
  });
};

export const ConvertLeadToStudent = (body: ConvertLeadToStudentDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/assignStudentToBatch`,
    data: body,
  });
};

export const UpdateLeadPriority = (body: UpdatePriorityDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/assignPriority`,
    data: body,
  });
};

export const UpdateLeadAssignee = (body: UpdateAssigneeDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/assignTeamMember`,
    data: body,
  });
};

export const GetDemoByEnquiryId = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `nurture/lead/demo?enquiryId=${id}`,
  });
};

export const EditDemoRequest = (body: CreateDemoDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead/demo`,
    data: body,
  });
};

export const ComposeEmail = (body: ComposeEmailDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/inbox`,
    data: body,
  });
};

export const GetInboxByLeadId = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/nurture/lead/inbox?enquiryId=${id}`,
  });
};

export const UpdateLeadEnquiryStatus = (body: LeadStatusUpdateDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead/updateEnquiryStatus `,
    data: body,
  });
};

export const GetBatchTimeByBatchId = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/batchCalendar/byBatch/${id}`,
  });
};

export const GetCourseDataWithCategoryForInstitute = (
  id: string,
  branchId: string,
  assigneeId: string
) => {
  let url = `/course/getCoursesWithCategoryForInstitute/${id}`;
  if (branchId || assigneeId) {
    url += `?`;
  }

  if (branchId) {
    url += `branchId=${branchId}`;
  }
  if (branchId && assigneeId) {
    url += `&`;
  }
  if (assigneeId) {
    url += `assigneeId=${assigneeId}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const GetNurtureLeads = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/paginate`,
    data: body,
  });
};

export const GetLeadListByInstituteAndContact = (
  id: string,
  phone?: string,
  email?: string
) => {
  let queryParams = new URLSearchParams();

  queryParams.append("instituteId", id);

  if (phone) {
    queryParams.append("leadContact", phone);
  }

  if (email) {
    queryParams.append("leadEmail", email);
  }

  return axios_institute_private({
    method: "GET",
    url: `nurture/lead/listByInstituteAndContact?${queryParams.toString()}`,
  });
};

export const DeleteNote = (body: DeleteNoteDTP) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/note`,
    data: body,
  });
};

export const EditNote = (body: EditNoteDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/note`,
    data: body,
  });
};

export const DeleteTask = (body: DeleteTaskDTO) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/task`,
    data: body,
  });
};

export const EditTask = (body: EditTaskDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/task`,
    data: body,
  });
};

export const GetDemoCalanderByCourseAndInstitute = (
  courseId: string,
  id: string
) => {
  return axios_institute_private({
    method: "GET",
    // url: `/course/getCoursesWithCategoryForInstitute/${id}`,
    url: `/nurture/lead/getBatchAndDemo?courseId=${courseId}&instituteId=${id}`,
  });
};

export const DeleteDemoBookedForLead = (body: DeleteDemoBookedDTO) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/nurture/lead/demo`,
    data: body,
  });
};

export const UpdateLeadEmiDetails = (body: leadEmiDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead/updatePaymentDetails`,
    data: body,
  });
};

export const GetEmiDataOfLead = (id: string) => {
  return axios_institute_private({
    method: "GET",
    // url: `emi?emiEntityName=ENQUIRY&emiEntityId=${id}`,
    url: `emi?emiEntityName=ENQUIRY&emiEntityId=${id}&loadEMIPayment=true`,
  });
};

export const SendPaymentLink = (body: sendEmailDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `nurture/lead/payment?actionType=sendPaymentLink`,
    data: body,
  });
};

export const DownloadPaymentReceipt = (body: DownloadReceiptDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `nurture/lead/payment?actionType=generatePaymentDocument`,
    data: body,
  });
};

export const EditRecordPayment = (body: RecordPaymentDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/nurture/lead/payment`,
    data: body,
  });
};

export const CreateSource = (body: CreateSourceDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institutions/enquirySource`,
    data: body,
  });
};

export const CreateComment = (body: CreateCommentDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/comment`,
    data: body,
  });
};

export const DeleteComment = (body: DeleteCommentDTO) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/institute/comment`,
    data: body,
  });
};

export const EditComment = (body: CreateCommentDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/comment`,
    data: body,
  });
};

export const GetPaymentReminder = (
  userId: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  let url = `/emi?limit=${limit}&userId=${userId}&actionTionToPerform=paymentReminder`;

  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const ClearPaymentReminder = (emiId: string) => {
  return axios_institute_private({
    method: "PUT",
    url: `/emi?emiId=${emiId}`,
  });
};

export const ClearAllPaymentReminder = (userId: string) => {
  return axios_institute_private({
    method: "PUT",
    url: `/emi?userId=${userId}`,
  });
};

export const GetPaymentReminderCount = (userId: string) => {
  let url = `/emi?userId=${userId}`;

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const StudentAutoDebit = (
  body: StudentAutoDebitDTO,
  actionType: string
) => {
  return axios_institute_private({
    method: "POST",
    url: `/studentAutoDebit?actionType=${actionType}`,
    data: body,
  });
};

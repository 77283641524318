import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CreateDemoRequest,
  CreateNote,
  CreateTask,
  GetBatchByInstitute,
  GetLeadActivityList,
  GetLeadById,
  GetLeadNoteList,
  GetLeadTaskList,
  GetLeadPaymentList,
  GetNurtureLeadBoard,
  NurtureLeadRequest,
  RecordPayment,
  UpdateLead,
  UpdateLeadSwimlaneStatus,
  ConvertLeadToStudent,
  UpdateLeadPriority,
  UpdateLeadAssignee,
  GetDemoByBatchId,
  GetDemoByDateAndBAtchRequest,
  GetDemoByEnquiryId,
  EditDemoRequest,
  ComposeEmail,
  GetInboxByLeadId,
  UpdateLeadEnquiryStatus,
  GetBatchTimeByBatchId,
  GetCourseDataWithCategoryForInstitute,
  GetNurtureLeads,
  GetLeadListByInstituteAndContact,
  DeleteNote,
  EditNote,
  DeleteTask,
  EditTask,
  GetDemoCalanderByCourseAndInstitute,
  DeleteDemoBookedForLead,
  UpdateLeadEmiDetails,
  GetEmiDataOfLead,
  SendPaymentLink,
  DownloadPaymentReceipt,
  EditRecordPayment,
  CreateSource,
  CreateComment,
  EditComment,
  DeleteComment,
  StudentAutoDebit,
} from "./nurture.request";
import {
  CreateDemoDTO,
  CreateNoteDTO,
  CreateTaskDTO,
  RecordPaymentDTO,
  LeadSwimlaneStatusUpdateDTO,
  // NurtureAddEnquiryDTO,
  NurtureInitialStateDTO,
  NurtureLead,
  ConvertLeadToStudentDTO,
  UpdatePriorityDTO,
  UpdateAssigneeDTO,
  GetDemoByDateAndBAtchDTO,
  EditDemoDTO,
  ComposeEmailDTO,
  LeadStatusUpdateDTO,
  UpdateTaskStatusDTO,
  DeleteDemoBookedDTO,
  leadEmiDTO,
  sendEmailDTO,
  DownloadReceiptDTO,
  CreateSourceDTO,
  CreateCommentDTO,
  StudentAutoDebitDTO,
} from "./types";
import { UpdateTaskStatus } from "../../Tasks/service/tasks.request";
import {
  setLeadExistData,
  setToastNotification,
} from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: NurtureInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  leadBoard: null,
  leadById: null,
  leadListByContact: null,
  demoByCourseAndInstitute: null,
  onLeadsData: null,
  courseDataWithCategory: null,
  leadInbox: null,
  leadActivityList: null,
  leadTaskList: null,
  leadNoteList: null,
  leadPaymentList: null,
  leadEmiData: null,
  leadCreateErrorData: null,
  leadUpdateErrorData: null,
  leadModalId: null,
  leadModalState: false,
  leadCreateSuccess: false,
  leadCreateError: false,
  leadCreateLoading: false,
  leadStatusUpdateSuccess: false,
  leadStatusUpdateError: false,
  leadStatusUpdateLoading: false,
  leadUpdateSuccess: false,
  leadUpdateError: false,
  leadUpdateLoading: false,
  createDemoLoading: false,
  createDemoSuccess: false,
  createDemoError: false,
  batchByInstitute: null,
  leadConvertionSuccess: false,
  leadConvertionError: false,
  leadConvertionLoading: false,
  leadPriorityUpdateSuccess: false,
  leadPriorityUpdateError: false,
  leadPriorityUpdateLoading: false,
  leadAssigneeUpdateSuccess: false,
  leadAssigneeUpdateError: false,
  leadAssigneeUpdateLoading: false,
  demoByBatchId: null,
  demoByEnquiryId: null,
  demoByBatchAndDate: null,
  editDemoLoading: false,
  editDemoSuccess: false,
  editDemoError: false,
  composeEmailLoading: false,
  composeEmailSuccess: false,
  composeEmailError: false,
  createNoteLoading: false,
  createNoteSuccess: false,
  createNoteError: false,
  createTaskLoading: false,
  createTaskSuccess: false,
  createTaskError: false,
  createPaymentLoading: false,
  createPaymentSuccess: false,
  createPaymentError: false,
  leadLostUpdateLoading: false,
  leadLostUpdateSuccess: false,
  leadLostUpdateError: false,
  batchTimeByBatchId: null,
  leadActivityLoading: false,
  onLeadsLoading: false,
  onLeadsSuccess: false,
  onLeadsError: false,

  leadListLoading: false,
  leadListSuccess: false,
  leadListError: false,
  deleteNoteLoading: false,
  deleteNoteSuccess: false,
  deleteNoteError: false,
  editNoteLoading: false,
  editNoteSuccess: false,
  editNoteError: false,
  deleteTaskLoading: false,
  deleteTaskSuccess: false,
  deleteTaskError: false,
  editTaskLoading: false,
  editTaskSuccess: false,
  editTaskError: false,
  // updateTaskStatusLoading: false,
  // updateTaskStatusSuccess: false,
  // updateTaskStatusError: false,
  deleteDemoBookedLoading: false,
  deleteDemoBookedSuccess: false,
  deleteDemoBookedError: false,
  leadTasksloading: false,
  paymentListLoading: false,
  noteListLoading: false,
  inboxLoading: false,
  leadBoardLoading: false,
  leadEmiDetailsLoading: false,
  leadEmiDetailsSuccess: false,
  leadEmiDetailsError: false,
  sendPaymentLinkLoading: false,
  sendPaymentLinkSuccess: false,
  sendPaymentLinkError: false,
  getEmiDataloading: false,
  downloadPaymentReceiptLoading: false,
  downloadPaymentReceiptSuccess: false,
  downloadPaymentReceiptError: false,
  leadByIdLoading: false,
  recordPaymentUpdateLoading: false,
  recordPaymentUpdateSuccess: false,
  recordPaymentUpdateError: false,
  createSourceLoading: false,
  createSourceSuccess: false,
  createSourceError: false,
  createCommentLoading: false,
  createCommentSuccess: false,
  createCommentError: false,
  updateCommentLoading: false,
  updateCommentSuccess: false,
  updateCommentError: false,
  deleteCommentLoading: false,
  deleteCommentSuccess: false,
  deleteCommentError: false,
  autoDebitError: false,
  autoDebitSuccess: false,
  autoDebitLoading: false,
};

export const getNurtureLeadBoard = createAsyncThunk(
  "nurture/leadBoard",
  async (id: string, thunkAPI: any) => {
    return GetNurtureLeadBoard(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateLeadSwimlaneStatus = createAsyncThunk(
  "nurture/updateLeadSwimlaneStatus",
  async (data: LeadSwimlaneStatusUpdateDTO, thunkAPI: any) => {
    return UpdateLeadSwimlaneStatus(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

// export const nurtureLead = createAsyncThunk(
//   "nurture/createLead",
//   (data: NurtureLead, { dispatch }) => {
//     return NurtureLeadRequest(data)
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const nurtureLead = createAsyncThunk(
  "nurture/createLead",
  async (data: NurtureLead, { rejectWithValue, dispatch }) => {
    try {
      const response = await NurtureLeadRequest(data);
      if (response) {
        dispatch(
          setToastNotification({
            message: response?.data?.message,
            type: "success",
            snackOpen: true,
          })
        );
      }

      return response?.data;
    } catch (error: any) {
      dispatch(
        setToastNotification({
          message: error?.response?.data?.message,
          type: "error",
          snackOpen: true,
        })
      );
      // Check if the error response exists and throw it using rejectWithValue
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        // If the structure is different or no response, throw a generic error
        return rejectWithValue({ message: "Something went wrong" });
      }
    }
  }
);

export const getLeadActivityList = createAsyncThunk(
  "nurture/lead/activityList",
  async (id: string, thunkAPI: any) => {
    return GetLeadActivityList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getLeadTaskList = createAsyncThunk(
  "nurture/lead/taskList",
  async (id: string, thunkAPI: any) => {
    return GetLeadTaskList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getLeadNoteList = createAsyncThunk(
  "nurture/lead/noteList",
  async (id: string, thunkAPI: any) => {
    return GetLeadNoteList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getLeadPaymentList = createAsyncThunk(
  "nurture/lead/paymentList",
  async (id: string, thunkAPI: any) => {
    return GetLeadPaymentList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateLead = createAsyncThunk(
  "nurture/updateLead",
  async (data: NurtureLead, thunkAPI: any) => {
    return UpdateLead(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        thunkAPI.dispatch(setLeadExistData(e?.response?.data?.data));
        throw e.response.data;
      });
  }
);

export const getLeadById = createAsyncThunk(
  "nurture/leadById",
  async (id: string, thunkAPI: any) => {
    return GetLeadById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const createNote = createAsyncThunk(
  "nurture/createNote",
  (data: CreateNoteDTO, { dispatch }) => {
    return CreateNote(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createTask = createAsyncThunk(
  "nurture/createTask",
  (data: CreateTaskDTO, { dispatch }) => {
    return CreateTask(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const recordPayment = createAsyncThunk(
  "nurture/recordPayment",
  (data: RecordPaymentDTO, { dispatch }) => {
    return RecordPayment(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createDemo = createAsyncThunk(
  "nurture/lead/demo",
  (data: CreateDemoDTO, { dispatch }) => {
    return CreateDemoRequest(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBatchByInstitute = createAsyncThunk(
  "batch/byInstitute/",
  async (id: string) => {
    return GetBatchByInstitute(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const convertLeadToStudent = createAsyncThunk(
  "nurture/convertLeadToStudent",
  (data: ConvertLeadToStudentDTO, { dispatch }) => {
    return ConvertLeadToStudent(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getDemoByBatchId = createAsyncThunk(
  "nurture/lead/demo?batchId",
  async (id: string) => {
    return GetDemoByBatchId(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getDemoByEnquiryId = createAsyncThunk(
  "nurture/lead/demo?enquiryId",
  async (id: string) => {
    return GetDemoByEnquiryId(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getDemoByBatchAndDate = createAsyncThunk(
  "nurture/lead/demo?batchId=''&demoStartDate=",
  (data: GetDemoByDateAndBAtchDTO) => {
    return GetDemoByDateAndBAtchRequest(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);
export const updateLeadPriority = createAsyncThunk(
  "nurture/updateLeadPriority",
  (data: UpdatePriorityDTO, { dispatch }) => {
    return UpdateLeadPriority(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateLeadAssignee = createAsyncThunk(
  "nurture/updateLeadAssignee",
  (data: UpdateAssigneeDTO, { dispatch }) => {
    return UpdateLeadAssignee(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const editDemo = createAsyncThunk(
  "nurture/lead/editDemo",
  (data: EditDemoDTO, { dispatch }) => {
    return EditDemoRequest(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getInoxByLeadId = createAsyncThunk(
  "nurture/getInoxByLeadId",
  async (id: string, thunkAPI: any) => {
    return GetInboxByLeadId(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const composeEmail = createAsyncThunk(
  "nurture/composeEmail",
  (data: ComposeEmailDTO, { dispatch }) => {
    return ComposeEmail(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateLeadEnquiryStatus = createAsyncThunk(
  "nurture/updateLeadStatus",
  async (data: LeadStatusUpdateDTO, thunkAPI: any) => {
    return UpdateLeadEnquiryStatus(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBatchTimeByBatchId = createAsyncThunk(
  "batchCalendar/byBatch/",
  async (id: string, thunkAPI: any) => {
    return GetBatchTimeByBatchId(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getCourseDataWithCategoryForInstitute = createAsyncThunk(
  "nurture/CourseDataWithCategoryForInstitute",
  async (data: any, thunkAPI: any) => {
    return GetCourseDataWithCategoryForInstitute(
      data?.id,
      data?.branchId,
      data?.assigneeId
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getLeadsForBoard = createAsyncThunk(
  "nurture/leadsForBoard",
  async (data: any, thunkAPI: any) => {
    return GetNurtureLeads(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getLeadListByInstituteAndContact = createAsyncThunk(
  "nurture/leadListByInstituteAndContact",
  async (data: any, thunkAPI: any) => {
    return GetLeadListByInstituteAndContact(data.id, data.phone, data.email)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deleteNote = createAsyncThunk(
  "nurture/deleteNote",
  async (data: any, thunkAPI: any) => {
    return DeleteNote(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const editNote = createAsyncThunk(
  "nurture/editNote",
  async (data: any, thunkAPI: any) => {
    return EditNote(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteTask = createAsyncThunk(
  "nurture/deleteTask",
  async (data: any, thunkAPI: any) => {
    return DeleteTask(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const editTask = createAsyncThunk(
  "nurture/editTask",
  async (data: any, thunkAPI: any) => {
    return EditTask(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

// export const updateTaskStatus = createAsyncThunk(
//   "tasks/updateTaskStatus",
//   async (data: UpdateTaskStatusDTO, thunkAPI: any) => {
//     return UpdateTaskStatus(data)
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const getDemoCalanderByCourseAndInstitute = createAsyncThunk(
  "nurture/getDemoCalanderByCourseAndInstitute",
  async (data: any, thunkAPI: any) => {
    return GetDemoCalanderByCourseAndInstitute(data.courseId, data.id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deleteDemoBookedForLead = createAsyncThunk(
  "user/deleteDemoBookedForLead",
  async (data: DeleteDemoBookedDTO, { dispatch }) => {
    return DeleteDemoBookedForLead(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateLeadEmiDetails = createAsyncThunk(
  "nurture/updateLeadEmiDetails",
  async (data: leadEmiDTO, thunkAPI: any) => {
    return UpdateLeadEmiDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getEmiDataOfLead = createAsyncThunk(
  "nurture/getEmiDataOfLead",
  async (id: string, thunkAPI: any) => {
    return GetEmiDataOfLead(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const sendPaymentLink = createAsyncThunk(
  "nurture/lead/sendPaymentLink",
  (data: sendEmailDTO, { dispatch }) => {
    return SendPaymentLink(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const downloadPaymentReceipt = createAsyncThunk(
  "nurture/lead/downloadPaymentReceipt",
  async (data: DownloadReceiptDTO, { dispatch }) => {
    try {
      const response = await DownloadPaymentReceipt(data);
      const pdfData = response?.data?.data;

      // Assuming pdfData is a base64-encoded PDF string
      const decodedData = atob(pdfData);
      const byteArray = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // const a = document.createElement("a");
      // a.style.display = "none";
      // a.href = url;
      // a.download = "downloaded.pdf"; // Specify the file name here
      // document.body.appendChild(a);
      // a.click();
      // URL.revokeObjectURL(url);
      // document.body.removeChild(a);

      window.open(url, "_blank");

      // Optionally, you can revoke the object URL to free up resources
      // URL.revokeObjectURL(url);

      return pdfData; // Return the PDF data if needed
    } catch (error: any) {
      dispatch(
        setToastNotification({
          message: error?.response?.data?.message,
          type: "error",
          snackOpen: true,
        })
      );
      // Handle the error, you might want to dispatch an action or log it
      throw error.response?.data;
    }
  }
);

// export const downloadPaymentReceipt = createAsyncThunk(
//   "nurture/lead/downloadPaymentReceipt",
//   async (data: DownloadReceiptDTO, { dispatch }) => {
//     try {
//       const response = await DownloadPaymentReceipt(data);
//       const pdfData = response?.data?.data;

//       // Assuming pdfData is a base64-encoded PDF string
//       const decodedData = atob(pdfData);
//       const byteArray = new Uint8Array(decodedData.length);

//       for (let i = 0; i < decodedData.length; i++) {
//         byteArray[i] = decodedData.charCodeAt(i);
//       }

//       const file = new File([byteArray], "payment_receipt.pdf", {
//         type: "application/pdf",
//       });

//       // Create a data URL to the PDF
//       const url = URL.createObjectURL(file);

//       // Create a new anchor element
//       const a = document.createElement("a");
//       a.href = url;
//       a.target = "_blank";
//       a.download = "payment_receipt.pdf"; // Set a default filename

//       // Trigger a click event to open the PDF in a new tab
//       a.click();

//       // Optionally, you can revoke the object URL to free up resources
//       URL.revokeObjectURL(url);

//       return pdfData; // Return the PDF data if needed
//     } catch (error: any) {
//       // Handle the error, you might want to dispatch an action or log it
//       throw error.response?.data;
//     }
//   }
// );

export const editRecordPayment = createAsyncThunk(
  "nurture/lead/editRecordPayment",
  (data: RecordPaymentDTO, { dispatch }) => {
    return EditRecordPayment(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createSource = createAsyncThunk(
  "nurture/createSource",
  (data: CreateSourceDTO, { dispatch }) => {
    return CreateSource(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createComment = createAsyncThunk(
  "nurture/createComment",
  (data: CreateCommentDTO, { dispatch }) => {
    return CreateComment(data)
      .then((response: any) => {
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteComment = createAsyncThunk(
  "nurture/deleteComment",
  async (data: any, thunkAPI: any) => {
    return DeleteComment(data)
      .then((response: any) => {
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const editComment = createAsyncThunk(
  "nurture/editComment",
  async (data: any, thunkAPI: any) => {
    return EditComment(data)
      .then((response: any) => {
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const studentAutoDebit = createAsyncThunk(
  "user/studentAutoDebit",
  async (
    { data, actionType }: { data: StudentAutoDebitDTO; actionType: string },
    thunkAPI: any
  ) => {
    return StudentAutoDebit(data, actionType)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const nurtureLeadSlice = createSlice({
  name: "nurture",
  initialState,
  reducers: {
    reset: () => initialState,
    resetLeadById: (state) => {
      state.leadById = null;
    },
    resetLeadListByContact: (state) => {
      state.leadListByContact = null;
    },
    resetDemoByEnquiryId: (state) => {
      state.demoByEnquiryId = null;
    },
    resetLeadUpdateSuccess: (state) => {
      state.leadUpdateSuccess = false;
      state.composeEmailSuccess = false;
      state.leadStatusUpdateSuccess = false;
    },
    resetTaskSuccess: (state) => {
      state.createTaskSuccess = false;
    },
    resetNoteSuccess: (state) => {
      state.createNoteSuccess = false;
    },
    resetDemoSuccess: (state) => {
      state.createDemoSuccess = false;
      state.editDemoSuccess = false;
    },
    resetPaymentSuccess: (state) => {
      state.createPaymentSuccess = false;
    },
    resetLeadLostSuccess: (state) => {
      state.leadLostUpdateSuccess = false;
    },
    resetLeadConvertion: (state) => {
      state.leadConvertionError = false;
    },
    resetLeadCreateSuccess: (state) => {
      state.leadCreateSuccess = false;
    },
    resetLeadActivityList: (state) => {
      state.leadActivityList = false;
    },
    resetLeadStatusUpdateError: (state) => {
      state.leadStatusUpdateError = false;
    },
    resetDeleteNoteSuccess: (state) => {
      state.deleteNoteSuccess = false;
    },
    resetEditNoteSuccess: (state) => {
      state.editNoteSuccess = false;
    },
    resetDeleteTaskSuccess: (state) => {
      state.deleteTaskSuccess = false;
    },
    resetEditTaskSuccess: (state) => {
      state.editTaskSuccess = false;
    },
    // resetUpdateTaskSuccess: (state) => {
    //   state.updateTaskStatusSuccess = false;
    // },
    resetLeadCreateError: (state) => {
      state.leadCreateError = false;
      // state.leadCreateErrorData = null;
    },
    resetLeadCreateErrorData: (state) => {
      state.leadCreateErrorData = null;
      state.leadUpdateErrorData = null;
    },
    setLeadCreateErrorData: (state, action) => {
      state.leadUpdateErrorData = null;
    },
    resetleadConvertionSuccess: (state) => {
      state.leadConvertionSuccess = false;
    },
    resetLeadError: (state) => {
      state.leadCreateError = false;
      state.leadUpdateError = false;
      state.leadStatusUpdateError = false;
      state.createNoteError = false;
      state.createTaskError = false;
      state.createPaymentError = false;
      state.createDemoError = false;
      state.leadConvertionError = false;
      state.leadPriorityUpdateError = false;
      state.leadAssigneeUpdateError = false;
      state.editDemoError = false;
      state.composeEmailError = false;
      state.leadLostUpdateError = false;
    },
    resetDemoState: (state) => {
      state.createDemoSuccess = false;
      state.editDemoSuccess = false;
      state.deleteDemoBookedSuccess = false;
    },
    resetTaskStatuses: (state) => {
      state.createTaskSuccess = false;
      state.createTaskError = false;
    },
    resetNoteStatuses: (state) => {
      state.createNoteSuccess = false;
      state.createNoteError = false;
    },
    resetEmailStatuses: (state) => {
      state.composeEmailSuccess = false;
      state.composeEmailError = false;
    },
    resetLeadLEmiData: (state) => {
      state.leadEmiData = null;
    },
    resetLeadLEmiStatus: (state) => {
      state.leadEmiDetailsError = false;
      state.leadEmiDetailsSuccess = false;
    },
    resetPaymentLinkStatus: (state) => {
      state.sendPaymentLinkSuccess = false;
      state.sendPaymentLinkError = false;
    },
    resetLeadPaymentData: (state) => {
      state.leadPaymentList = null;
    },
    resetDownloadStatuses: (state) => {
      state.downloadPaymentReceiptSuccess = false;
      state.downloadPaymentReceiptError = false;
    },
    resetDemoBatchesData: (state) => {
      state.demoByCourseAndInstitute = null;
    },
    resetSourceStatuses: (state) => {
      state.createSourceSuccess = false;
      state.createSourceError = false;
    },
    resetCancelPaymentStatuses: (state) => {
      state.recordPaymentUpdateSuccess = false;
      state.recordPaymentUpdateError = false;
    },
    resetLeadListSuccess: (state) => {
      state.leadListSuccess = false;
    },
    resetCommentStatuses: (state) => {
      state.updateCommentSuccess = false;
      state.updateCommentError = false;
      state.deleteCommentSuccess = false;
      state.deleteCommentError = false;
      state.createCommentSuccess = false;
      state.createCommentError = false;
    },
    setLeadModalState: (state) => {
      state.leadModalState = true;
    },
    resetLeadModalState: (state) => {
      state.leadModalState = false;
    },
    setLeadModalLeadId: (state, action) => {
      const data = action.payload;
      state.leadModalId = data;
    },
    resetLeadPopupTabDatas: (state) => {
      state.leadTaskList = null;
      state.leadEmiData = null;
      state.leadNoteList = null;
      state.leadPaymentList = null;
      state.leadActivityList = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNurtureLeadBoard.pending, (state) => {
      state.leadBoardLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getNurtureLeadBoard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadBoardLoading = false;
        state.success = true;
        state.error = false;
        state.leadBoard = action?.payload;
      }
    );
    builder.addCase(
      getNurtureLeadBoard.rejected,
      (state, action: AnyAction) => {
        state.leadBoardLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updateLeadSwimlaneStatus.pending, (state) => {
      state.leadStatusUpdateLoading = true;
      state.leadStatusUpdateSuccess = false;
      state.leadStatusUpdateError = false;
    });
    builder.addCase(
      updateLeadSwimlaneStatus.fulfilled,
      (state, action: AnyAction) => {
        state.leadStatusUpdateLoading = false;
        state.leadStatusUpdateSuccess =
          action?.payload?.message || "Lead Status Updated";
        state.leadStatusUpdateError = false;
      }
    );
    builder.addCase(
      updateLeadSwimlaneStatus.rejected,
      (state, action: AnyAction) => {
        state.leadStatusUpdateLoading = false;
        state.leadStatusUpdateSuccess = false;
        state.leadStatusUpdateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getLeadActivityList.pending, (state) => {
      state.leadActivityLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getLeadActivityList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadActivityLoading = false;
        state.success = true;
        state.error = false;
        state.leadActivityList = action?.payload;
      }
    );
    builder.addCase(
      getLeadActivityList.rejected,
      (state, action: AnyAction) => {
        state.leadActivityLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getLeadTaskList.pending, (state) => {
      state.leadTasksloading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getLeadTaskList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadTasksloading = false;
        state.success = true;
        state.error = false;
        state.leadTaskList = action?.payload;
      }
    );
    builder.addCase(getLeadTaskList.rejected, (state, action: AnyAction) => {
      state.leadTasksloading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getLeadNoteList.pending, (state) => {
      state.noteListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getLeadNoteList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.noteListLoading = false;
        state.success = true;
        state.error = false;
        state.leadNoteList = action?.payload;
      }
    );
    builder.addCase(getLeadNoteList.rejected, (state, action: AnyAction) => {
      state.noteListLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getLeadPaymentList.pending, (state) => {
      state.paymentListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getLeadPaymentList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.paymentListLoading = false;
        state.success = true;
        state.error = false;
        state.leadPaymentList = action?.payload;
      }
    );
    builder.addCase(getLeadPaymentList.rejected, (state, action: AnyAction) => {
      state.paymentListLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(nurtureLead.pending, (state) => {
      state.leadCreateLoading = true;
      state.leadCreateSuccess = false;
      state.leadCreateError = false;
    });
    builder.addCase(nurtureLead.fulfilled, (state, action: AnyAction) => {
      state.leadCreateLoading = false;
      state.leadCreateSuccess = action?.payload?.message || "Lead Created";
      state.leadCreateError = false;
    });
    builder.addCase(nurtureLead.rejected, (state, action: AnyAction) => {
      state.leadCreateLoading = false;
      state.leadCreateSuccess = false;
      state.leadCreateError = action?.error?.message || "Something went wrong";
      // state.leadCreateError = action?.error?.data || "Something went wrong";
      state.leadCreateErrorData = action || null;
    });
    builder.addCase(updateLead.pending, (state) => {
      state.leadUpdateLoading = true;
      state.leadUpdateSuccess = false;
      state.leadUpdateError = false;
    });
    builder.addCase(updateLead.fulfilled, (state, action: AnyAction) => {
      state.leadUpdateLoading = false;
      state.leadUpdateSuccess = action?.payload?.message || "Lead Updated";
      state.leadUpdateError = false;
    });
    builder.addCase(updateLead.rejected, (state, action: AnyAction) => {
      state.leadUpdateLoading = false;
      state.leadUpdateSuccess = false;
      state.leadUpdateError = action?.error?.message || "Something went wrong";
      state.leadUpdateErrorData = action || null;
    });
    builder.addCase(getLeadById.pending, (state) => {
      state.leadByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getLeadById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadByIdLoading = false;
        state.success = true;
        state.error = false;
        state.leadById = action?.payload;
      }
    );
    builder.addCase(getLeadById.rejected, (state, action: AnyAction) => {
      state.leadByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(createNote.pending, (state) => {
      state.createNoteLoading = true;
      state.createNoteSuccess = false;
      state.createNoteError = false;
    });
    builder.addCase(createNote.fulfilled, (state, action: AnyAction) => {
      state.createNoteLoading = false;
      state.createNoteSuccess = action?.payload?.message || "Note Created";
      state.createNoteError = false;
    });
    builder.addCase(createNote.rejected, (state, action: AnyAction) => {
      state.createNoteLoading = false;
      state.createNoteSuccess = false;
      state.createNoteError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(createTask.pending, (state) => {
      state.createTaskLoading = true;
      state.createTaskSuccess = false;
      state.createTaskError = false;
    });
    builder.addCase(createTask.fulfilled, (state, action: AnyAction) => {
      state.createTaskLoading = false;
      state.createTaskSuccess = action?.payload?.message || "Task Created";
      state.createTaskError = false;
    });
    builder.addCase(createTask.rejected, (state, action: AnyAction) => {
      state.createTaskLoading = false;
      state.createTaskSuccess = false;
      state.createTaskError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(recordPayment.pending, (state) => {
      state.createPaymentLoading = true;
      state.createPaymentSuccess = false;
      state.createPaymentError = false;
    });
    builder.addCase(recordPayment.fulfilled, (state, action: AnyAction) => {
      state.createPaymentLoading = false;
      state.createPaymentSuccess =
        action?.payload?.message || "Payment Recorded";
      state.createPaymentError = false;
    });
    builder.addCase(recordPayment.rejected, (state, action: AnyAction) => {
      state.createPaymentLoading = false;
      state.createPaymentSuccess = false;
      state.createPaymentError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(createDemo.pending, (state) => {
      state.createDemoLoading = true;
      state.createDemoSuccess = false;
      state.createDemoError = false;
    });
    builder.addCase(createDemo.fulfilled, (state, action: AnyAction) => {
      state.createDemoLoading = false;
      state.createDemoSuccess = action?.payload?.message || "Demo Booked";
      state.createDemoError = false;
    });
    builder.addCase(createDemo.rejected, (state, action: AnyAction) => {
      state.createDemoLoading = false;
      state.createDemoSuccess = false;
      state.createDemoError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getBatchByInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBatchByInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.batchByInstitute = action?.payload;
      }
    );
    builder.addCase(
      getBatchByInstitute.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getDemoByBatchId.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getDemoByBatchId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.demoByBatchId = action?.payload;
      }
    );
    builder.addCase(getDemoByBatchId.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getDemoByBatchAndDate.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getDemoByBatchAndDate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.demoByBatchAndDate = action?.payload;
      }
    );
    builder.addCase(
      getDemoByBatchAndDate.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(convertLeadToStudent.pending, (state) => {
      state.leadConvertionLoading = true;
      state.leadConvertionSuccess = false;
      state.leadConvertionError = false;
    });
    builder.addCase(
      convertLeadToStudent.fulfilled,
      (state, action: AnyAction) => {
        state.leadConvertionLoading = false;
        state.leadConvertionSuccess =
          action?.payload?.message || "Lead converted to Student";
        state.leadConvertionError = false;
      }
    );
    builder.addCase(
      convertLeadToStudent.rejected,
      (state, action: AnyAction) => {
        state.leadConvertionLoading = false;
        state.leadConvertionSuccess = false;
        state.leadConvertionError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updateLeadPriority.pending, (state) => {
      state.leadPriorityUpdateLoading = true;
      state.leadPriorityUpdateSuccess = false;
      state.leadPriorityUpdateError = false;
    });
    builder.addCase(
      updateLeadPriority.fulfilled,
      (state, action: AnyAction) => {
        state.leadPriorityUpdateLoading = false;
        state.leadPriorityUpdateSuccess =
          action?.payload?.message || "Priority Updated";
        state.leadPriorityUpdateError = false;
      }
    );
    builder.addCase(updateLeadPriority.rejected, (state, action: AnyAction) => {
      state.leadPriorityUpdateLoading = false;
      state.leadPriorityUpdateSuccess = false;
      state.leadPriorityUpdateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateLeadAssignee.pending, (state) => {
      state.leadAssigneeUpdateLoading = true;
      state.leadAssigneeUpdateSuccess = false;
      state.leadAssigneeUpdateError = false;
    });
    builder.addCase(
      updateLeadAssignee.fulfilled,
      (state, action: AnyAction) => {
        state.leadAssigneeUpdateLoading = false;
        state.leadAssigneeUpdateSuccess =
          action?.payload?.message || "Assignee Updated";
        state.leadAssigneeUpdateError = false;
      }
    );
    builder.addCase(updateLeadAssignee.rejected, (state, action: AnyAction) => {
      state.leadAssigneeUpdateLoading = false;
      state.leadAssigneeUpdateSuccess = false;
      state.leadAssigneeUpdateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getDemoByEnquiryId.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.demoByEnquiryId = null;
    });
    builder.addCase(
      getDemoByEnquiryId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.demoByEnquiryId = action?.payload;
      }
    );
    builder.addCase(getDemoByEnquiryId.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(editDemo.pending, (state) => {
      state.editDemoLoading = true;
      state.editDemoSuccess = false;
      state.editDemoError = false;
    });
    builder.addCase(editDemo.fulfilled, (state, action: AnyAction) => {
      state.editDemoLoading = false;
      state.editDemoSuccess = action?.success?.message || "Demo Updated";
      state.editDemoError = false;
    });
    builder.addCase(editDemo.rejected, (state, action: AnyAction) => {
      state.editDemoLoading = false;
      state.editDemoSuccess = false;
      state.editDemoError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getInoxByLeadId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.inboxLoading = false;
        state.success = true;
        state.error = false;
        state.leadInbox = action?.payload;
      }
    );
    builder.addCase(getInoxByLeadId.rejected, (state, action: AnyAction) => {
      state.inboxLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getInoxByLeadId.pending, (state) => {
      state.inboxLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(composeEmail.pending, (state) => {
      state.composeEmailLoading = true;
      state.composeEmailSuccess = false;
      state.composeEmailError = false;
    });
    builder.addCase(composeEmail.fulfilled, (state, action: AnyAction) => {
      state.composeEmailLoading = false;
      state.composeEmailSuccess = action?.payload?.message || "Email Sent";
      state.composeEmailError = false;
    });
    builder.addCase(composeEmail.rejected, (state, action: AnyAction) => {
      state.composeEmailLoading = false;
      state.composeEmailSuccess = false;
      state.composeEmailError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateLeadEnquiryStatus.pending, (state) => {
      state.leadLostUpdateLoading = true;
      state.leadLostUpdateSuccess = false;
      state.leadLostUpdateError = false;
    });
    builder.addCase(
      updateLeadEnquiryStatus.fulfilled,
      (state, action: AnyAction) => {
        state.leadLostUpdateLoading = false;
        state.leadLostUpdateSuccess =
          action?.payload?.message || "Lead Status Updated";
        state.leadLostUpdateError = false;
      }
    );
    builder.addCase(
      updateLeadEnquiryStatus.rejected,
      (state, action: AnyAction) => {
        state.leadLostUpdateLoading = false;
        state.leadLostUpdateSuccess = false;
        state.leadLostUpdateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      getBatchTimeByBatchId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.batchTimeByBatchId = action?.payload;
      }
    );
    builder.addCase(
      getBatchTimeByBatchId.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getBatchTimeByBatchId.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getCourseDataWithCategoryForInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getCourseDataWithCategoryForInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.courseDataWithCategory = action?.payload;
      }
    );
    builder.addCase(
      getCourseDataWithCategoryForInstitute.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getLeadsForBoard.pending, (state) => {
      state.onLeadsLoading = true;
      state.onLeadsSuccess = false;
      state.onLeadsError = false;
    });
    builder.addCase(
      getLeadsForBoard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.onLeadsLoading = false;
        state.onLeadsSuccess = true;
        state.onLeadsError = false;
        state.onLeadsData = action?.payload;
      }
    );
    builder.addCase(getLeadsForBoard.rejected, (state, action: AnyAction) => {
      state.onLeadsLoading = false;
      state.onLeadsSuccess = false;
      state.onLeadsError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getLeadListByInstituteAndContact.pending, (state) => {
      state.leadListLoading = true;
      state.leadListSuccess = false;
      state.leadListError = false;
    });
    builder.addCase(
      getLeadListByInstituteAndContact.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadListLoading = false;
        state.leadListSuccess = true;
        state.leadListError = false;
        state.leadListByContact = action?.payload;
      }
    );
    builder.addCase(
      getLeadListByInstituteAndContact.rejected,
      (state, action: AnyAction) => {
        state.leadListLoading = false;
        state.leadListSuccess = false;
        state.leadListError = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(deleteNote.pending, (state) => {
      state.deleteNoteLoading = true;
      state.deleteNoteSuccess = false;
      state.deleteNoteError = false;
    });

    builder.addCase(deleteNote.fulfilled, (state, action: AnyAction) => {
      state.deleteNoteLoading = false;
      state.deleteNoteSuccess =
        action?.payload?.message || "Note deleted Successfully";
      state.deleteNoteError = false;
    });
    builder.addCase(deleteNote.rejected, (state, action: AnyAction) => {
      state.deleteNoteLoading = false;
      state.deleteNoteSuccess = false;
      state.deleteNoteError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(editNote.pending, (state) => {
      state.editNoteLoading = true;
      state.editNoteSuccess = false;
      state.editNoteError = false;
    });
    builder.addCase(editNote.fulfilled, (state, action: PayloadAction<any>) => {
      state.editNoteLoading = false;
      state.editNoteSuccess = action?.payload?.message || "Note Updated";
      state.editNoteError = false;
    });
    builder.addCase(editNote.rejected, (state, action: AnyAction) => {
      state.editNoteLoading = false;
      state.editNoteSuccess = false;
      state.editNoteError = action?.error?.message || "Something went wrong";
    });

    builder.addCase(deleteTask.pending, (state) => {
      state.deleteTaskLoading = true;
      state.deleteTaskSuccess = false;
      state.deleteTaskError = false;
    });
    builder.addCase(
      deleteTask.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteTaskLoading = false;
        state.deleteTaskSuccess = action?.payload?.message || "Task Deleted ";
        state.deleteTaskError = false;
      }
    );
    builder.addCase(deleteTask.rejected, (state, action: AnyAction) => {
      state.deleteTaskLoading = false;
      state.deleteTaskSuccess = false;
      state.deleteTaskError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(editTask.pending, (state) => {
      state.editTaskLoading = true;
      state.editTaskSuccess = false;
      state.editTaskError = false;
    });
    builder.addCase(editTask.fulfilled, (state, action: PayloadAction<any>) => {
      state.editTaskLoading = false;
      state.editTaskSuccess = action?.payload?.message || "Task Updated";
      state.editTaskError = false;
    });
    builder.addCase(editTask.rejected, (state, action: AnyAction) => {
      state.editTaskLoading = false;
      state.editTaskSuccess = false;
      state.editTaskError = action?.error?.message || "Something went wrong";
    });
    // builder.addCase(updateTaskStatus.pending, (state) => {
    //   state.updateTaskStatusLoading = true;
    //   state.updateTaskStatusSuccess = false;
    //   state.updateTaskStatusError = false;
    // });
    // builder.addCase(
    //   updateTaskStatus.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.updateTaskStatusLoading = false;
    //     state.updateTaskStatusSuccess = true;
    //     state.updateTaskStatusError = false;
    //   }
    // );
    // builder.addCase(updateTaskStatus.rejected, (state, action: AnyAction) => {
    //   state.updateTaskStatusLoading = false;
    //   state.updateTaskStatusSuccess = false;
    //   state.updateTaskStatusError =
    //     action?.error?.message || "Something went wrong";
    // });
    builder.addCase(
      getDemoCalanderByCourseAndInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.demoByCourseAndInstitute = action?.payload;
      }
    );
    builder.addCase(
      getDemoCalanderByCourseAndInstitute.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getDemoCalanderByCourseAndInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(deleteDemoBookedForLead.pending, (state) => {
      state.deleteDemoBookedLoading = true;
      state.deleteDemoBookedSuccess = false;
      state.deleteDemoBookedError = false;
    });
    builder.addCase(
      deleteDemoBookedForLead.fulfilled,
      (state, action: AnyAction) => {
        state.deleteDemoBookedLoading = false;
        state.deleteDemoBookedSuccess =
          action?.payload?.message || "Demo cancelled";
        state.deleteDemoBookedError = false;
      }
    );
    builder.addCase(
      deleteDemoBookedForLead.rejected,
      (state, action: AnyAction) => {
        state.deleteDemoBookedLoading = false;
        state.deleteDemoBookedSuccess = false;
        state.deleteDemoBookedError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updateLeadEmiDetails.pending, (state) => {
      state.leadEmiDetailsLoading = true;
      state.leadEmiDetailsSuccess = false;
      state.leadEmiDetailsError = false;
    });
    builder.addCase(
      updateLeadEmiDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadEmiDetailsLoading = false;
        state.leadEmiDetailsSuccess = action?.payload?.message || "Emi created";
        state.leadEmiDetailsError = false;
      }
    );
    builder.addCase(
      updateLeadEmiDetails.rejected,
      (state, action: AnyAction) => {
        state.leadEmiDetailsLoading = false;
        state.leadEmiDetailsSuccess = false;
        state.leadEmiDetailsError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      getEmiDataOfLead.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getEmiDataloading = false;
        state.success = true;
        state.error = false;
        state.leadEmiData = action?.payload;
      }
    );
    builder.addCase(getEmiDataOfLead.rejected, (state, action: AnyAction) => {
      state.getEmiDataloading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getEmiDataOfLead.pending, (state) => {
      state.getEmiDataloading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(sendPaymentLink.pending, (state) => {
      state.sendPaymentLinkLoading = true;
      state.sendPaymentLinkSuccess = false;
      state.sendPaymentLinkError = false;
    });
    builder.addCase(sendPaymentLink.fulfilled, (state, action: AnyAction) => {
      state.sendPaymentLinkLoading = false;
      state.sendPaymentLinkSuccess =
        action?.payload?.message || "Payment link sucessfully sent";
      state.sendPaymentLinkError = false;
    });
    builder.addCase(sendPaymentLink.rejected, (state, action: AnyAction) => {
      state.sendPaymentLinkLoading = false;
      state.sendPaymentLinkSuccess = false;
      state.sendPaymentLinkError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(downloadPaymentReceipt.pending, (state) => {
      state.downloadPaymentReceiptLoading = true;
      state.downloadPaymentReceiptSuccess = false;
      state.downloadPaymentReceiptError = false;
    });
    builder.addCase(
      downloadPaymentReceipt.fulfilled,
      (state, action: AnyAction) => {
        state.downloadPaymentReceiptLoading = false;
        state.downloadPaymentReceiptSuccess =
          action?.payload?.message || "Receipt downloaded sucessfully";
        state.downloadPaymentReceiptError = false;
      }
    );
    builder.addCase(
      downloadPaymentReceipt.rejected,
      (state, action: AnyAction) => {
        state.downloadPaymentReceiptLoading = false;
        state.downloadPaymentReceiptSuccess = false;
        state.downloadPaymentReceiptError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(editRecordPayment.pending, (state) => {
      state.recordPaymentUpdateLoading = true;
      state.recordPaymentUpdateSuccess = false;
      state.recordPaymentUpdateError = false;
    });
    builder.addCase(editRecordPayment.fulfilled, (state, action: AnyAction) => {
      state.recordPaymentUpdateLoading = false;
      state.recordPaymentUpdateSuccess =
        action?.payload?.message || "Payment link cancelled";
      state.recordPaymentUpdateError = false;
    });
    builder.addCase(editRecordPayment.rejected, (state, action: AnyAction) => {
      state.recordPaymentUpdateLoading = false;
      state.recordPaymentUpdateSuccess = false;
      state.recordPaymentUpdateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createSource.pending, (state) => {
      state.createSourceLoading = true;
      state.createSourceSuccess = false;
      state.createSourceError = false;
    });
    builder.addCase(createSource.fulfilled, (state, action: AnyAction) => {
      state.createSourceLoading = false;
      state.createSourceSuccess = action?.payload?.message || "Source Created";
      state.createSourceError = false;
    });
    builder.addCase(createSource.rejected, (state, action: AnyAction) => {
      state.createSourceLoading = false;
      state.createSourceSuccess = false;
      state.createSourceError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createComment.pending, (state) => {
      state.createCommentLoading = true;
      state.createCommentSuccess = false;
      state.createCommentError = false;
    });
    builder.addCase(createComment.fulfilled, (state, action: AnyAction) => {
      state.createCommentLoading = false;
      state.createCommentSuccess =
        action?.payload?.message || "Comment Added Successfully";
      state.createCommentError = false;
    });
    builder.addCase(createComment.rejected, (state, action: AnyAction) => {
      state.createCommentLoading = false;
      state.createCommentSuccess = false;
      state.createCommentError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(deleteComment.pending, (state) => {
      state.deleteCommentLoading = true;
      state.deleteCommentSuccess = false;
      state.deleteCommentError = false;
    });
    builder.addCase(deleteComment.fulfilled, (state, action: AnyAction) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess =
        action?.payload?.message || "Comment deleted Successfully";
      state.deleteCommentError = false;
    });
    builder.addCase(deleteComment.rejected, (state, action: AnyAction) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess = false;
      state.deleteCommentError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(editComment.pending, (state) => {
      state.updateCommentLoading = true;
      state.updateCommentSuccess = false;
      state.updateCommentError = false;
    });
    builder.addCase(editComment.fulfilled, (state, action: AnyAction) => {
      state.updateCommentLoading = false;
      state.updateCommentSuccess =
        action?.payload?.message || "Comment Updated Successfully";
      state.updateCommentError = false;
    });
    builder.addCase(editComment.rejected, (state, action: AnyAction) => {
      state.updateCommentLoading = false;
      state.updateCommentSuccess = false;
      state.updateCommentError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(studentAutoDebit.pending, (state) => {
      state.autoDebitLoading = true;
      state.autoDebitSuccess = false;
      state.autoDebitError = false;
    });
    builder.addCase(studentAutoDebit.fulfilled, (state, action: AnyAction) => {
      state.autoDebitLoading = false;
      state.autoDebitSuccess = action?.payload?.message || "Payment Done";
      state.autoDebitError = false;
    });
    builder.addCase(studentAutoDebit.rejected, (state, action: AnyAction) => {
      state.autoDebitLoading = false;
      state.autoDebitSuccess = false;
      state.autoDebitError = action?.payload?.message || "Something went wrong";
    });
  },
});

export default nurtureLeadSlice.reducer;
export const {
  reset,
  resetLeadById,
  resetDemoByEnquiryId,
  resetLeadUpdateSuccess,
  resetTaskSuccess,
  resetNoteSuccess,
  resetDemoSuccess,
  resetPaymentSuccess,
  resetLeadLostSuccess,
  resetLeadConvertion,
  resetLeadCreateSuccess,
  resetLeadActivityList,
  resetLeadStatusUpdateError,
  resetDeleteNoteSuccess,
  resetEditNoteSuccess,
  resetDeleteTaskSuccess,
  resetEditTaskSuccess,
  resetLeadListByContact,
  resetLeadCreateError,
  resetleadConvertionSuccess,
  resetLeadError,
  resetDemoState,
  resetNoteStatuses,
  resetTaskStatuses,
  resetEmailStatuses,
  resetLeadLEmiData,
  resetPaymentLinkStatus,
  resetLeadLEmiStatus,
  resetLeadPaymentData,
  resetDownloadStatuses,
  resetDemoBatchesData,
  resetSourceStatuses,
  resetCancelPaymentStatuses,
  resetLeadListSuccess,
  resetLeadCreateErrorData,
  resetCommentStatuses,
  resetLeadModalState,
  setLeadModalState,
  setLeadModalLeadId,
  resetLeadPopupTabDatas,
} = nurtureLeadSlice.actions;

import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CreateCourseRequest,
  GetAllCourseByInstituteRequest,
  UpdateCourseRequest,
  GetCourseByIdRequest,
} from "./course.request";
import { CreateCourseDTO, createCourseIntialStateDTO } from "./types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: createCourseIntialStateDTO = {
  success_createCourse: false,
  error_createCourse: false,
  loading_createCourse: false,
  success_updateCourse: false,
  error_updateCourse: false,
  loading_updateCourse: false,
  loading_getAllCourseByInstituteRequest: false,
  success_getAllCourseByInstituteRequest: false,
  error_getAllCourseByInstituteRequest: false,
  loading_getCourseByIdRequest: false,
  error_getCourseByIdRequest: false,
  success_getCourseByIdRequest: false,
  courseById: null,
  courses: [],
  success: false,
  error: false,
  loading: false,
  courseByIdLoading: false,
};

export const createCourse = createAsyncThunk(
  "user/course",
  (data: CreateCourseDTO, { dispatch }) => {
    return CreateCourseRequest(data)
      .then((response: any) => {
        if (response.status === 200) {
        }
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const updateCourse = createAsyncThunk(
  "user/updatecourse",
  (data: CreateCourseDTO, { dispatch }) => {
    return UpdateCourseRequest(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const getCourseByIdRequest = createAsyncThunk(
  "user/getCourseByIdRequest",
  (id: any) => {
    return GetCourseByIdRequest(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);
export const getAllCourseByInstituteRequest = createAsyncThunk(
  "user/getAllCourseByInstituteRequest",
  (id: any) => {
    return GetAllCourseByInstituteRequest(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserDetails: (state, action: PayloadAction<CreateCourseDTO>) => {
      return { ...state, userDetails: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCourse.pending, (state) => {
      state.loading_createCourse = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(createCourse.fulfilled, (state, action: AnyAction) => {
      state.loading_createCourse = false;
      state.success = action?.payload?.message || "Course Created";
      state.error = false;
    });
    builder.addCase(createCourse.rejected, (state, action: AnyAction) => {
      state.loading_createCourse = false;
      state.success = false;
      state.error = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getAllCourseByInstituteRequest.pending, (state) => {
      state.loading_getAllCourseByInstituteRequest = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAllCourseByInstituteRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading_getAllCourseByInstituteRequest = false;
        state.success = true;
        state.error = false;
        state.courses = action?.payload;
      }
    );
    builder.addCase(
      getAllCourseByInstituteRequest.rejected,
      (state, action: AnyAction) => {
        state.loading_getAllCourseByInstituteRequest = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updateCourse.pending, (state) => {
      state.loading_updateCourse = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(updateCourse.fulfilled, (state, action: AnyAction) => {
      state.loading_updateCourse = false;
      state.success = action?.payload?.message || "Course Updated";
      state.error = false;
    });
    builder.addCase(updateCourse.rejected, (state, action: AnyAction) => {
      state.loading_updateCourse = false;
      state.success = false;
      state.error = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getCourseByIdRequest.pending, (state) => {
      state.courseByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getCourseByIdRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.courseByIdLoading = false;
        state.success = true;
        state.error = false;
        state.courseById = action?.payload;
      }
    );
    builder.addCase(
      getCourseByIdRequest.rejected,
      (state, action: AnyAction) => {
        state.courseByIdLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default courseSlice.reducer;
export const { reset, setUserDetails } = courseSlice.actions;
